import React, { useEffect, useState } from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';
import { formatBytes } from '../functions/utils';

const StorageBar = ({ refreshTrigger }) => {
    const [storageData, setStorageData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStorageData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch('https://dellfi.serv.uni-hohenheim.de/backend/get-user-storage', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error('Failed to fetch storage details');
                }
                const data = await response.json();
                setStorageData(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchStorageData();
    }, [refreshTrigger]); // Fetch data again when refreshTrigger changes

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!storageData) {
        return <Typography>Loading storage data...</Typography>;
    }

    const { driveSizeLimit, usedSpace, availableSpace } = storageData;
    const totalSpace = driveSizeLimit;
    const usedPercentage = (usedSpace / totalSpace) * 100;

    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h6">Drive Information</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Used: {formatBytes(usedSpace)}</Typography>
                <Typography variant="body2">Available: {formatBytes(availableSpace)}</Typography>
                <Typography variant="body2">Total: {formatBytes(driveSizeLimit)}</Typography>
            </Box>

            <LinearProgress
                variant="determinate"
                value={usedPercentage}
                sx={{
                    marginTop: 1,
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: usedPercentage > 80 ? '#f44336' : '#d1d1d1', // Red if used > 80%
                }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 1 }}>
                <Typography variant="body2" color={usedPercentage > 80 ? 'error' : 'textPrimary'}>
                    {usedPercentage.toFixed(2)}% used
                </Typography>
            </Box>
        </Box>
    );
};

export default StorageBar;
