import React, { useState, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { validatePassword } from '../functions/utils';

const ChangePassword = () => {
    const { authenticatedUser, setAuthenticatedUser } = useContext(AuthContext);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [messageVisible, setMessageVisible] = useState(false);
    const [messageOpacity, setMessageOpacity] = useState(1);

    const showMessage = (msg) => {
        setMessage(msg);
        setMessageVisible(true);
        // Start fade-out after a delay
        setTimeout(() => {
            setMessageOpacity(0);
        }, 4000);

        setTimeout(() => {
            setMessageVisible(false);
            setMessageOpacity(1); // Reset 
        }, 5000);
    } 

    const handlePasswordChange = (e) => {
        e.preventDefault();

        if (newPassword !== newPasswordConfirm) {
            showMessage('Die Passwörter stimmen nicht überein.');
            return;
        }

        if (!validatePassword(newPassword)) {
            showMessage('Das Passwort sollte mindestens acht Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten.');
            return;
        }

        fetch(`https://dellfi.serv.uni-hohenheim.de/backend/users/${authenticatedUser.id}/settings`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                password: currentPassword,  // This is your old password
                newPassword
            })
        })
        .then(response => response.json())
        .then(data => {
            if (data.message) {
                showMessage(data.message);
            }
            if (data.user) {
                setAuthenticatedUser(data.user);
            }
        })
        .catch(error => console.error('Error:', error));
    };

    return (
        <div>
            <form onSubmit={handlePasswordChange}>
                <h2>Passwort ändern</h2>

                <label>
                    Aktuelles Passwort:
                    <input type="password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} required />
                </label>

                <div className="password-container">
                    <input type={passwordVisible ? 'text' : 'password'} placeholder="Neues Passwort" value={newPassword} onChange={e => setNewPassword(e.target.value)} required />
                    <i id={passwordVisible ? 'hide' : 'show'} onClick={() => setPasswordVisible(!passwordVisible)}></i>
                </div>

                <div className="password-container">
                    <input type={confirmPasswordVisible ? 'text' : 'password'} placeholder="Neues Passwort bestätigen" value={newPasswordConfirm} onChange={e => setNewPasswordConfirm(e.target.value)} required />
                    <i id={confirmPasswordVisible ? 'hide' : 'show'} onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}></i>
                </div>

                <input type="submit" value="Passwort ändern" />
            </form>

            {messageVisible && (
                <div className="message" style={{ opacity: messageOpacity }}>
                    {message}
                </div>
            )}
        </div>
    );
}

export default ChangePassword;
