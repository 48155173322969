import React, { useState, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import './SignUpLogin.css';
import { validatePassword } from '../../functions/utils';

const SignUpLogin = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isSignUp = location.pathname === '/signup';
  const { setAuthenticatedUser } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = '';

    if (isSignUp) {
      if (password !== passwordConfirm) {
        setMessage('Die Passwörter stimmen nicht überein.');
        return;
      }

      if (!validatePassword(password)) {
        setMessage('Das Passwort sollte mindestens acht Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten.');
        return;
      }
      // send data to backend (mocked)
      console.log('Sending signup data:', { email, firstName, lastName, password });
      url = 'https://dellfi.serv.uni-hohenheim.de/backend/signup';
    } else {
      // send data to backend (mocked)
      console.log('Sending login data:', { email, password });
      url = 'https://dellfi.serv.uni-hohenheim.de/backend/login';
    }

    fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password,
        firstName: isSignUp ? firstName : undefined,
        lastName: isSignUp ? lastName : undefined,
      })
    })
      .then(response => response.text())
      .then(body => {
        try {
          const data = JSON.parse(body);
          // handle your data here

          // if we get a message back, we successfully registered. If not, we successfully logged in
          if (data.message) {
            setMessage(data.message);
            navigate('/login');
          } else {
            localStorage.setItem('token', data.token);
            setAuthenticatedUser(data.user);
            navigate(currentPath === '/signup' || currentPath === '/login' ? '/' : currentPath);
          }
        } catch (error) {
          // If the response isn't valid JSON, this will throw an error
          console.error('Unable to parse JSON:', body);
          setMessage("Es ist ein Fehler aufgetreten. Versuchen Sie es später noch einmal.")
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setMessage("Es ist ein Fehler aufgetreten. Versuchen Sie es später noch einmal.")
      });
  };

  return (
    <div className='backgroundContainer-signup'>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <h2>{isSignUp ? 'Registrieren' : 'Login'}</h2>

          <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />

          {isSignUp && (
            <>
              <input type="text" placeholder="Vorname" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
              <input type="text" placeholder="Nachname" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
            </>
          )}

          <div className="password-container">
            <input type={passwordVisible ? 'text' : 'password'} placeholder="Passwort" value={password} onChange={(e) => setPassword(e.target.value)} required />
            <i id={passwordVisible ? 'hide' : 'show'} onClick={() => setPasswordVisible(!passwordVisible)}></i>
          </div>

          {isSignUp &&
            <div className="password-container">
              <input type={confirmPasswordVisible ? 'text' : 'password'} placeholder="Passwort bestätigen" value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} required />
              <i id={confirmPasswordVisible ? 'hide' : 'show'} onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}></i>
            </div>
          }

          <p>{message}</p>

          <button type="submit">{isSignUp ? 'Jetzt Registrieren' : 'Einloggen'}</button>
        </form>

        {isSignUp
          ? <Link className='lightlink' to="/login">Du hast schon einen Account? Login.</Link>
          : <Link className='lightlink' to="/signup">Noch keinen Account? Registrieren.</Link>
        }
      </div>
    </div>
  );
};

export default SignUpLogin;
