import React, { useEffect, useState } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { IconButton, Tooltip } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import CircularProgress from '@mui/material/CircularProgress';
import "./SpeechToText.css";

const SpeechToText = ({ onInput, className }) => {
    const [isActive, setActive] = useState(false);
    const {
        interimTranscript,
        finalTranscript,
        listening,
        resetTranscript,
    } = useSpeechRecognition();

    useEffect(() => {
        if (isActive) {
            // Send interim transcript while recording
            if (interimTranscript) {
                onInput(interimTranscript);
            }
            // Send the final transcript when done
            if (finalTranscript !== '') {
                console.log('Final transcript:', finalTranscript);
                onInput(finalTranscript);
                setActive(false); // Deactivate after final transcript is received
            }
        }
    }, [interimTranscript, finalTranscript, isActive, onInput]);

    const toggleMicrophone = (e) => {
        e.preventDefault();
        if (!isActive) {
            resetTranscript();
            SpeechRecognition.startListening({ language: 'de-DE' });
        } else {
            SpeechRecognition.stopListening();
        }
        setActive(!isActive);
    };

    return (
        <div className="speech-to-text-container">
            <Tooltip title={listening ? "Stop listening" : "Start listening"} arrow>
                <IconButton onClick={toggleMicrophone} className={className}>
                    {listening ? <MicOffIcon color="error" /> : <MicIcon color="primary" />}
                </IconButton>
            </Tooltip>
            {listening && <CircularProgress size={24} className="loading-indicator" />}
        </div>
    );
};

export default SpeechToText;
